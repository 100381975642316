import shuffle from 'knuth-shuffle-seeded';

const seededSampleSize = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  array : any[],
  size : number,
  seed : number,
) => shuffle([...array], seed)
  .slice(0, size);

export default seededSampleSize;
