import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Tab as TabComponent } from '@fuww/library/src/Tabs';
import { Link } from '../../routes.mjs';

const tabPropertyTypes = {
  route: PropTypes.string.isRequired,
  label: PropTypes.node,
  params: PropTypes.shape({}),
};

type TabProperties = InferProps<typeof tabPropertyTypes>;

const Tab = ({
  route, params, ...tabProperties
}: TabProperties) => (
  <Link
    route={route}
    params={params}
    scroll={false}
    passHref
  >
    <TabComponent
      component="a"
      {...tabProperties}
    />
  </Link>
);

Tab.propTypes = tabPropertyTypes;

Tab.defaultProps = {
  label: null,
  params: {},
};

export default Tab;
