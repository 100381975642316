import React from 'react';
import PropTypes from 'prop-types';
import { JobsTabBar } from '@fuww/library/src/Jobs';
import Tab from '../../Tab';
import { useSiteContext } from '../../SiteContext';

const JobSearchTabs = ({ profile, params }) => {
  const { locale, isCareerPage } = useSiteContext();
  const { companySlug, profilePage } = params;

  const {
    showAboutPage,
    showWorkingAtPage,
    showNewsPage,
    aboutPageTitle,
    workingAtPageTitle,
    newsPageTitle,
  } = profile.page;

  const companyJobsTabRoute = isCareerPage
    ? `careers.${locale}` : `jobs.${locale}`;
  const companyEbpTabRoute = isCareerPage
    ? `careers-ebp.${locale}` : `company-ebp.${locale}`;

  const tabs = [
    <Tab
      key="description"
      value="description"
      label={profile.company?.displayName}
      route={companyJobsTabRoute}
      params={{
        companySlug,
      }}
      originalCase
    />,
    ...(showAboutPage ? [
      <Tab
        key="about"
        value="about"
        label={aboutPageTitle || 'About'}
        route={companyEbpTabRoute}
        params={{
          companySlug,
          profilePage: 'about',
        }}
        originalCase
      />,
    ] : []),
    ...(showWorkingAtPage ? [
      <Tab
        key="working-at"
        value="working-at"
        label={workingAtPageTitle || 'Working At'}
        route={companyEbpTabRoute}
        params={{
          companySlug,
          profilePage: 'working-at',
        }}
        originalCase
      />,
    ] : []),
    ...(showNewsPage ? [
      <Tab
        key="news"
        value="news"
        label={newsPageTitle || 'News'}
        route={companyEbpTabRoute}
        params={{
          companySlug,
          profilePage: 'news',
        }}
        originalCase
      />,
    ] : []),
  ];

  return tabs.length > 1 && (
    <JobsTabBar
      value={profilePage || 'description'}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons={false}
    >
      {tabs}
    </JobsTabBar>
  );
};

JobSearchTabs.propTypes = {
  profile: PropTypes.shape({
    company: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    page: PropTypes.shape({
      showAboutPage: PropTypes.bool,
      showWorkingAtPage: PropTypes.bool,
      showNewsPage: PropTypes.bool,
      aboutPageTitle: PropTypes.string,
      workingAtPageTitle: PropTypes.string,
      newsPageTitle: PropTypes.string,
    }).isRequired,
  }).isRequired,
  params: PropTypes.shape({
    companySlug: PropTypes.string,
    profilePage: PropTypes.string,
  }),
};

JobSearchTabs.defaultProps = {
  params: {},
};

export default JobSearchTabs;
